import SelfServeTab from "../pages/tabs/SelfServeTab/index.jsx";

const tabs = [
    {
        label: 'Welcome',
        component: <div></div>
    },
    {
        label: 'Jobs Portal',
        component: <div></div>
    },
    {
        label: 'Self Serve',
        component: <SelfServeTab />
    },
];

export default tabs;