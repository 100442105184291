import { Link, Stack, Typography } from "@mui/material";

const CrowdAccountReset = (props) => {
    
    return(
        <Stack spacing={5}>
        <Typography variant="body3">
            A Crowd account password reset email can be requested via the{" "}
            <Link sx={{fontWeight:800}} underline="hover"
              href="https://crowd.tools.btcsp.co.uk/console/forgottenlogindetails!execute.action"
              target="_blank"
            >
              Crowd reset page.
            </Link>
          </Typography>

          <Typography variant="body3">
            The Crowd reset page is accessible from BT Webtops when connected
            to the Intranet - via local LAN, or when remotely connected AO,
            DA/VPN, Pulse
          </Typography>

          <Typography variant="body3" sx={{fontStyle:'italic', fontWeight:200, fontSize:12}}>
          Note: please contact the tools team if resetting your password doesn't resolve your access issues (see "Connect with us" below).
          </Typography>

          <Typography variant="body3" sx={{fontStyle:'italic', fontWeight: 200, fontSize:12}}>
          For BT/EE users, the Crowd username is usually the first part of their email (e.g., tools.support for tools.support@bt.com).
          </Typography>
        </Stack>
    );
}

export default CrowdAccountReset;