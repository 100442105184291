import { QueryClient } from "@tanstack/react-query";
import store from "../store/store";
import { setAlert } from "../store/appStore";

const queryClient = new QueryClient({
    defaultOptions: {
      mutations: {
        onError: (error) => {
          // Handle error globally if not handled in the api call function
          console.error('Global mutation error:', error);
          store.dispatch(setAlert({
            message: error.response?.data?.message ?? error.message,
            alertLevel: "error"
          }))          
        },
      },
    },
  });

export default queryClient;