// self serve constants

export const BT_ALLOWED_ADDRESSES = [
    "@bt.com",
    "@ee.com",
    "@ee.co.uk",
    "openreach.co.uk", 
    "plus.net"
];

export const HTTPS_STATUS_CODE_KEYS = ["status_code"];
export const DATA_KEYS = [""]
export const MESSAGE_KEYS = ["status_message","failure"];
export const SELF_SERVE_BASE_URL = "https://0f8rfl4ys1.execute-api.eu-west-1.amazonaws.com/prd";

export const CREATE_CROWD_ACCOUNT_URL = "/selfserve/usercreation/request";