import { Box } from "@mui/material";
import SelfServeOptions from "./SelfServeOptions";
import LogIn from "./LogIn";
const LandingPage = (props) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        padding: 10,
        paddingTop:'3%',
        paddingBottom:15
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          alignItems: { xs: "center", sm: "flex-start" },
          flexGrow: { xs: 1, sm: 0 },
          columnGap: { sm: 40, md: 100 },
          rowGap: { xs: 20 },
        }}
      >
        <SelfServeOptions />
        <LogIn />
      </Box>
    </Box>
  );
};

export default LandingPage;
