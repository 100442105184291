
const appContainerStyle = {
    container:{
        margin: 0,
        minHeight: '100vh',
    },
    titleContainer:{
        display: "flex", alignItems: "center", padding: 5
    },
    title:{
        fontSize: "1.063rem",
        fontWeight: 700,
        maxWidth: 50,
        lineHeight: "18px",
        marginLeft: "5px",
      },
      logo:{
        height: '50px'
      },


};


export default appContainerStyle;