import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { Box } from "@mui/material";
import { useState } from "react";
import NewCrowdAccountRequest from "./options/NewCrowdAccount.jsx";
import NewJiraProject from './options/NewJiraProject.jsx';
import PendingRequests from "./options/PendingRequests.jsx";
import Drawer from "../../../components/Drawer/Drawer.jsx"
import CustomList from "../../../components/CutomList/CustomList.jsx"

const SelfServeTab = (props) => {
  const OPTIONS = {
    PENDING_REQUESTS: 0,
    NEW_CROWD_ACCOUNT: 1,
    RESET_CROWD_PASSWORD: 2,
    NEW_JIRA_PROJECT: 3,
  };

  const [selectedOption, setSelectedOption] = useState(OPTIONS.PENDING_REQUESTS);

  function renderOptionView(selectedOption) {
    let optionView = <></>;
    switch (selectedOption) {
      case OPTIONS.PENDING_REQUESTS:
        optionView = <PendingRequests />;
        break;
      case OPTIONS.NEW_CROWD_ACCOUNT:
        optionView = <NewCrowdAccountRequest />;
        break;
      case OPTIONS.RESET_CROWD_PASSWORD:
        optionView = <div>2</div>;
        break;
      case OPTIONS.NEW_JIRA_PROJECT:
        optionView = <NewJiraProject />;
        break;
      default:
        break;
    }

    return optionView;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
      <Drawer>
        <CustomList
          value={selectedOption}
        listNavItems={[
            {
              text: "Pending Requests",
              icon: <DashboardOutlinedIcon />,
              onClick: () => setSelectedOption(OPTIONS.PENDING_REQUESTS),
            },
            {
              text: "New Crowd Account",
              icon: <PersonAddAltOutlinedIcon />,
              onClick: () => setSelectedOption(OPTIONS.NEW_CROWD_ACCOUNT),
            },
            {
              text: "Reset Crowd Password",
              icon: <LockResetOutlinedIcon />,
              onClick: () => setSelectedOption(OPTIONS.RESET_CROWD_PASSWORD),
            },
            {
              text: "New Jira Project",
              icon: <AddchartOutlinedIcon />,
              onClick: () => setSelectedOption(OPTIONS.NEW_JIRA_PROJECT),
            },
          ]}
        />
      </Drawer>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        {renderOptionView(selectedOption)}
      </Box>
    </Box>
  );
};

export default SelfServeTab;
