import { createBrowserRouter } from "react-router-dom";
import AppContainer from "../components/AppContainer/AppContainer";
import LandingPage from "../pages/LandingPage";
import Dashboard from "../pages/Dashboard";

const router = createBrowserRouter([
    {
        path: "/",
        element: <AppContainer />,
        children:[
            {
                path:"",
                element: <LandingPage />,
            },{
                path:"dashboard",
                element: <Dashboard />
            }
        ]
    }
]);


export default router;