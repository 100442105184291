import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Typography
} from "@mui/material";
import { useState } from "react";
import CrowdAccountRequest from "./CrowdAccountRequest";
import CrowdAccountReset from "./CrowdAccountReset";

const SelfServeOptions = () => {
  const [isExpanded, setExpanded] = useState("accordion1");

  const onAccordionClick = (accordion) => (event, isExpanded) => {
    setExpanded(isExpanded ? accordion : false);
  };

  return (
    <Box
      sx={{
        width: "300px",
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
        rowGap: 10,
      }}
    >
      <Typography variant="h5">Anonymous Self Serve Options</Typography>
      <Box>
        <Accordion
          expanded={isExpanded === "accordion1"}
          onChange={onAccordionClick("accordion1")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Request a Crowd Account
          </AccordionSummary>
          <AccordionDetails>
            <CrowdAccountRequest />
          </AccordionDetails>
        </Accordion>
        
        <Accordion
          expanded={isExpanded === "accordion2"}
          onChange={onAccordionClick("accordion2")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Reset Crowd Password
          </AccordionSummary>
          <AccordionDetails>
            <CrowdAccountReset />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default SelfServeOptions;
