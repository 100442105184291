import axiosClientSelfServe from "../config/axios/selfServe"
import { CREATE_CROWD_ACCOUNT_URL } from "../config/constants/selfServe"
import { useMutation } from "@tanstack/react-query";


export const useCrowdAccountCreation = (data) => {
    return useMutation({
        mutationFn:  async (payload) => {
                const response = await axiosClientSelfServe.post(CREATE_CROWD_ACCOUNT_URL, payload);
                return response?.data;
    },
    })
}