import { Alert, Snackbar, Zoom } from "@mui/material";

const CustomAlert = (props) => {
  return (
    <Snackbar
      sx={{
        minWidth: 300,
        maxWidth: 500,
        "& .MuiAlert-root": {
          width: "100%",
          wordWrap: "break-word",
          whiteSpace: "normal",
          overflow: "hidden",
        },
        ...props.sx
      }}
      open={props.open}
      onClose={props.onClose}
      autoHideDuration={props.autoHideDuration}
      anchorOrigin={{ vertical: "bottom", horizontal: "right", ...props.anchorOrigin }}
      TransitionComponent={Zoom}
      TransitionProps={{
        timeout: { enter: 200, exit: 300 },
        easing: {
          enter: "ease-in-out",
          exit: "ease-out",
        },
        ...props.TransitionProps
      }}
    >
      <Alert severity={props.severity} variant={props.variant} onClose={props.onClose}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;