import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alertMessage: null,
    alertLevel: "info",
    isLoggedIn: false,
    user: null
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAlert: (state, action) => {
            state.alertMessage = action.payload.message;
            state.alertLevel = action.payload.alertLevel
        },

        clearAlert: (state) => {
            state.alertMessage = null;
            state.alertLevel = "info";
        },


    }

});

export const {setAlert, setAlertLevel, clearAlert} = appSlice.actions;
export default appSlice;