import { Stack, TextField, Typography } from "@mui/material";

const CustomTextField = (props) => {

    return(
        <Stack spacing={3}>
            <Typography>{props?.labelText}</Typography>
            <TextField {...props}/>
        </Stack>
    );
};

export default CustomTextField;