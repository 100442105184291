import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Grid2,
  IconButton,
  Toolbar,
  Typography,
  useColorScheme,
  // useMediaQuery,
} from "@mui/material";
import appContainerStyle from "../../components/AppContainer/appContainerStyle";
import Footer from "../Footer/Footer";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import logo from "../../assets/btlogo.png";

const FallBack = (props) => {
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const { mode, setMode } = useColorScheme();

  return (
    <Grid2
      container
      direction="column"
      columns={1}
      sx={appContainerStyle.container}
    >
      <AppBar position="static" enableColorOnDark>
        <Toolbar>
          <Box sx={appContainerStyle.titleContainer}>
            <img src={logo} alt="bt-logo" style={appContainerStyle.logo} />
            <Typography sx={appContainerStyle.title}>TOOLS PORTAL</Typography>
          </Box>
          <Box sx={{ marginLeft: "auto" }}>
            <IconButton
              onClick={() => {
                mode === "dark" ? setMode("light") : setMode("dark");
              }}
            >
              {mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Grid2
        item
        container
        sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
      >
        <Alert
          variant="outlined"
          severity="error"
          sx={{ width: 700, height: 300, textOverflow: "ellipsis" }}
        >
          <AlertTitle>{props.error?.message}</AlertTitle>
          {props.error?.stack}
        </Alert>
      </Grid2>
      <Footer links={[
          { text: "Networks Jira", url: "https://jira.tvac.bt.co.uk" },
          { text: "Networks Wiki", url: "https://wiki.tvac.bt.co.uk" },
          { text: "Networks Gitlab", url: "https://code.tvac.bt.co.uk" },
          { text: "Cloud Portal", url: "https://portal.core.btcsp.bt.co.uk" },
          ]}
 />
    </Grid2>
  );
};

export default FallBack;
