import { Button, Grid2, Stack, Typography } from "@mui/material";
import jiralogo from "../../../../assets/jiralogo.png";
import CustomTextField from "../../../../components/CustomTextField/CustomTextField";

const NewJiraProject = (props) => {
  const fields = [
    {
      label: "Email",
      id: "email",
    },
    {
      label: "Project Name",
      id: "projectName",
    },
    {
      label: "Project Key",
      id: "projectKey",
    },
    {
      label: "Project Lead Email",
      id: "leadMail",
    },
    {
      label: "Board Type",
      id: "boardType",
    },
  ];
  return (
    <Stack sx={{ paddingTop: 25, border: 0, alignItems: "flex-start" }}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          columnGap: 5,
          marginLeft: "35%",
        }}
      >
        <img src={jiralogo} style={{ height: 40 }} alt="jira logo"/>
        <Typography variant="h5">Request New Jira Project</Typography>
      </Stack>
      <Grid2
        container
        columns={12}
        sx={{ border: 0, marginTop: 15, marginLeft: "20%" }}
        columnSpacing={60}
        rowSpacing={10}
        maxWidth={"530px"}
      >
        <Grid2 container item size={12} flexBasis={"column"}>
          {fields.map((field) => {
            return (
              <Grid2 item size={6}>
                <CustomTextField
                  labelText={field.label}
                  InputProps={{
                    sx: {
                      height: "40px",
                      width: "250px",
                      padding: "12px",
                    },
                  }}
                />
              </Grid2>
            );
          })}
        </Grid2>
          <Button variant="contained" sx={{ marginLeft:'auto', marginRight: 'auto', marginTop:10 }}>
            Submit
          </Button>
      </Grid2>
    </Stack>
  );
};

export default NewJiraProject;
