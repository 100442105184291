import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useColorScheme,
  // useMediaQuery,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import logo from "../../assets/btlogo.png";
import ErrorBoundary from "../../components/Error/ErrorBoundary";
import Footer from "../../components/Footer/Footer";
import CustomAlert from "../Alert/CustomAlert";
import { useSelector, useDispatch } from 'react-redux';
import {clearAlert} from "../../store/appStore";

/*
 * Component for structural layout for application including
 * AppBar, Footer, Main content area and Light/Dark mode switch.
 * Ensures a fluid layout where the main content dynamically adjusts
 * between the header and footer.
 */


const AppContainer = (props) => {
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const { mode, setMode } = useColorScheme();
  const dispatchAction = useDispatch();
  const alertMessage = useSelector((state) => state.app.alertMessage);
  const alertLevel = useSelector((state) => state.app.alertLevel)


  const hasError = () => {
    const value = alertMessage !== null && alertMessage && alertMessage !=="";
    return value;
  }

  return (
    <ErrorBoundary>
      <Stack sx={{ minHeight: "100vh" }}>
        <AppBar
          position="sticky"
          enableColorOnDark
          sx={{ backgroundImage: "none" }}
        >
          <Toolbar>
            <Box sx={{ display: "flex", alignItems: "center", padding: 5 }}>
              <img src={logo} alt="bt-logo" style={{ height: "50px" }} />
              <Typography
                sx={{
                  fontSize: "1.063rem",
                  fontWeight: 700,
                  maxWidth: 50,
                  lineHeight: "18px",
                  marginLeft: "5px",
                }}
              >
                TOOLS PORTAL
              </Typography>
            </Box>
            <Box sx={{ marginLeft: "auto" }}>
              <IconButton
                onClick={() => {
                  mode === "dark" ? setMode("light") : setMode("dark");
                }}
                sx={{color:'#ffffff'}}
              >
                {mode === "dark" ? (
                  <DarkModeOutlinedIcon />
                ) : (
                  <LightModeOutlinedIcon />
                )}
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        {/* placeholder to display components from child routes */}
        <Outlet />
        <CustomAlert severity={alertLevel} open={hasError()} message={alertMessage} onClose={() => {dispatchAction(clearAlert())}} autoHideDuration={5000} />
        <Footer links={[
          { text: "Networks Jira", url: "https://jira.tvac.bt.co.uk" },
          { text: "Networks Wiki", url: "https://wiki.tvac.bt.co.uk" },
          { text: "Networks Gitlab", url: "https://code.tvac.bt.co.uk" },
          { text: "Cloud Portal", url: "https://cloud-portal.core.btcsp.co.uk" },
          ]}
 />
      </Stack>
    </ErrorBoundary>
  );
};

export default AppContainer;
