import { Button, Divider, Grid2, Link, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const {t} = useTranslation();

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      divider={<Divider orientation="vertical" thickness={10} flexItem />}
      spacing={{ xs: 10, sm: 15 }}
      minHeight={150}
      padding={{ xs: 10, sm: 10 }}
      marginTop={"auto"}
      backgroundColor="footer.main"
    >
      <Stack
        sx={{
          width: { xs: "100%", sm: "45%" },
        }}
        spacing={4}
      >
        <Typography variant="h6">{t("footer.quick_links")}</Typography>
        <Grid2 container row wrap="wrap" sx={{width:{lg: '50%', md:'60%', sm:'80%'}}} rowSpacing={5}>
        {props.links?.map((link, idx) => {
            return (
              <Grid2 item size={{xs:12, sm:6}} key={idx}>
              <Link
                underline="hover"
                href={link.url ?? "#"}
                sx={{ textTransform: "capitalize", fontWeight: 400 }}
              >
                {t(`footer.${link.text}`)}
              </Link>
              </Grid2>
            );
          })}
        </Grid2>

      </Stack>
      <Stack
        sx={{
          width: { xs: "100%", sm: "50%" },
        }}
        spacing={4}
      >
        <Typography variant="h6" color="footer.primaryText">
          {t("footer.connect_with_us")}
        </Typography>
        <Typography variant="body2" color="footer.secondaryText">
        {t("footer.connect_with_us_content.section1")} <Link underline="hover" href="https://jira.tvac.bt.co.uk/projects/BTTSUPPORT" target="_blank">{t("footer.connect_with_us_content.section2")}</Link> {t("footer.connect_with_us_content.section3")} <Link underline="hover" href="https://teams.microsoft.com/l/channel/19%3A2fc05c491d0542b8b0a531b6935d8993%40thread.tacv2/Tools%20Support%20(Public)?groupId=f730ccc6-e314-49db-b096-cbb0a48fc968&tenantId=a7f35688-9c00-4d5e-ba41-29f146377ab0" target="_blank">{t("footer.connect_with_us_content.section4")}</Link> {t("footer.connect_with_us_content.section5")} <Link underline="hover" href="mailto:tools.support@bt.com">tools.support@bt.com</Link>
        </Typography>
        {/* <Button
          variant="outlined"
          sx={{ width: "100px"}}
        >
          Feedback
        </Button> */}
      </Stack>
    </Stack>
  );
};

export default Footer;
