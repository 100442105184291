import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import tabs from "../../config/tabs";

const Dashboard = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  function handleTabClick(e, value) {
    setSelectedTab(value);
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: "90vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleTabClick}
        variant="fullWidth"
        sx={{
          background: "#342077",
          "& .MuiTabs-indicator": {
            backgroundColor: (theme) => theme.palette.tabIndicator.main,
          },
        }}
      >
        {tabs?.map((tab) => {
          return (
            <Tab
              label={tab.label}
              disableRipple
              sx={{
                color: "#ffffff",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#4d25ad",
                },
                "&.Mui-selected": {
                  backgroundColor: "#4d25ad",
                  color: "#ffffff",
                },
              }}
            />
          );
        })}
      </Tabs>
      <Box sx={{ flexGrow: 1, flexDirection: "column", display: "flex"}}>
        {tabs[selectedTab]?.component}
      </Box>
    </Box>
  );
};

export default Dashboard;
