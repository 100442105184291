import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material";

const CustomStyledList = styled(List)({
    '& .MuiListItem-root':{
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "0px",
        paddingRight: "0px",
    },
    '& .MuiListItemButton-root': {
        paddingLeft: 20,
        paddingRight: 20,
      },
      '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,

      },
      '& .MuiSvgIcon-root': {
        fontSize: 20,
        fontWeight:400

      },
});

const CustomList = (props) => {

  return(
    <CustomStyledList sx={props.sx}>
      {props.listNavItems?.map((listNavItem, index) => {
        return(
          <ListItem>
            <ListItemButton onClick={listNavItem?.onClick} color="red">
              <ListItemIcon
                            {...((props.value === index) && {sx: {color: 'primary.main'}})}

              >
                {listNavItem?.icon}
              </ListItemIcon>
              <ListItemText primary={listNavItem?.text} 
              {...((props.value === index) && {sx: {color: 'primary.main'}})}
              
           
              
              />
            </ListItemButton>
          </ListItem>
        );
      })}
      {props?.children}
    </CustomStyledList>
  );
}


export default CustomList;