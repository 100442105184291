import axios from 'axios';
import { DATA_KEYS, HTTPS_STATUS_CODE_KEYS, MESSAGE_KEYS, SELF_SERVE_BASE_URL } from '../constants/selfServe';

// axios client for self serve apis
const axiosClientSelfServe = axios.create({
    baseURL: SELF_SERVE_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

//transforms the api response format to a common format
axiosClientSelfServe.interceptors.response.use(response => {

    //check if any of the keys in list are present in response
    const statusCodeKey = HTTPS_STATUS_CODE_KEYS.find(key => key in response.data);
    const dataKey = DATA_KEYS.find(key => key in response.data);
    const messageKey = MESSAGE_KEYS.find(key => key in response.data);

    // transform the custom keys to standard http reponse keys
    if(statusCodeKey){
        response.data.status = response.data[statusCodeKey];
        delete response.data[statusCodeKey];
    }

    if(dataKey){
        response.data.data = response.data[dataKey];
        delete response.data[dataKey];
    }

    if(messageKey){
        if(Array.isArray(response.data[messageKey]))
            response.data.message = response.data[messageKey].join("-");
        else
            response.data.message = response.data[messageKey]
    
        delete response.data[messageKey];
    }


    return response;
}, 
error => {
    const statusCodeKey = HTTPS_STATUS_CODE_KEYS.find(key => key in error.response.data);
    const dataKey = DATA_KEYS.find(key => key in error.response.data);
    const messageKey = MESSAGE_KEYS.find(key => key in error.response.data);
    if(statusCodeKey){
        error.response.data.status = error.response.data[statusCodeKey];
        delete error.response.data[statusCodeKey];
    }

    if(dataKey){
        error.response.data.data = error.response.data[dataKey];
        delete error.response.data[dataKey];
    }
        
    if(messageKey){
        if(Array.isArray(error.response.data[messageKey]))
            error.response.data.message = error.response.data[messageKey].join("-");
        else
            error.response.data.message = error.response.data[messageKey];

        delete error.response.data[messageKey];
    }


    return Promise.reject(error);
})


export default axiosClientSelfServe;