export const ERRORS = {
    INVALID_MAIL: 1001,
    INVALID_DOMAIN: 1002
};

export const SUCCESS = {
    VALID_MAIL: 2001
};

export const ERROR_MESSAGES = {
    [ERRORS.INVALID_MAIL]: 'Invalid mail id',
    [ERRORS.INVALID_DOMAIN]: 'Not a BT domain mail'
};

export const SUCCESS_MESSAGES = {
    [SUCCESS.VALID_MAIL]: ''
};