import { Box, Button, Stack, Typography } from "@mui/material";
import crowdlogo from "../../../../assets/crowdlogo.png";
import CustomTextField from "../../../../components/CustomTextField/CustomTextField";

const NewCrowdAccountRequest = (props) => {
  return (
    <Box
      sx={{
        marginLeft: "35%",
        marginRight: "auto",
        paddingTop: 25,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          columnGap: 5,
        }}
      >
        <img src={crowdlogo} style={{ height: "40px" }} alt="crowd logo"/>
        <Typography variant="h5">Request New Crowd Account</Typography>
      </Stack>
      <Stack
        sx={{
          flexDirection: "column",
          rowGap: 10,
          alignItems: "center",
          marginTop: 25,
        }}
      >
        <CustomTextField labelText={"Email"}   InputProps={{
    sx: {
      height: '40px', 
      width:'250px',
      padding: '12px',
    },
  }}/>
        <Button
          variant="contained"
          sx={{ width: "95px", height:'37px' }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  );
};

export default NewCrowdAccountRequest;
